import './App.css';
import { Button, Card, CardActions, CardHeader, CardMedia, createTheme, ThemeProvider } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#012A2D"
    },
    secondary: {
      main: "#032842"
    },
    background: {
      paper: 'rgba(214, 213, 213, 0.75)',
      default: 'rgba(214, 213, 213, 0.75)'
    }
  }
});

function App() {

  const style = {
    backgroundImage: 'url(background.jpeg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100vw',
    height: '100vh',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={style}>
        <Card
          sx={{
            p: 2,
            textAlign: 'center'
          }}
        >
          <CardHeader 
            title="CBRE|ESI - Application Management Suite"
            titleTypographyProps={{
              fontSize: '30px',
              fontWeight: 600
            }}
          />
          <CardMedia
            component="img"
            height="500"
            image="images/ams-niagara-build.jpeg"
            alt="Paella dish"
          />
          <CardActions
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              pt: 2
            }}
          >
            <Button
              variant="contained"
              startIcon={<DownloadForOfflineIcon />}
              size="large"
              href= {window.location.href + "download/app"}
              target="_blank"
            >
              Download
            </Button>
          </CardActions>
        </Card>
      </div>
    </ThemeProvider>
  );
}

export default App;
